import React from 'react';
import '../styles/search-tab.css';
import Form from 'react-bootstrap/Form';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Col from 'react-bootstrap/Col';
import { Link } from 'gatsby';

const SearchTab = () => {
  return (
    <div className="SearchTab">
      <Tabs defaultActiveKey="Car model &amp; year" id="uncontrolled-tab-example" style={{ background: '#f5f5f5' }}>
        <Tab
          eventKey="Car model &amp; year"
          title="Car model &amp; year"
          style={{ padding: '20px', background: '#fff', border: '1px solid #dee2e6', borderTop: 'none' }}
        >
          <Form.Row>
            <Form.Group as={Col} md="5" controlId="validationCustom01">
              <Form.Label>Car make</Form.Label>
              <Form.Control required type="text" placeholder="Car make" />
            </Form.Group>
            <Form.Group as={Col} md={{ span: 5, offset: 1 }} controlId="validationCustom01">
              <Form.Label>Car model</Form.Label>
              <Form.Control required type="text" placeholder="Car model" />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="5" controlId="validationCustom01">
              <Form.Label>Engine</Form.Label>
              <Form.Control required type="text" placeholder="Engine" />
            </Form.Group>
            <Form.Group as={Col} md={{ span: 5, offset: 1 }} controlId="validationCustom01">
              <Form.Label>Model year</Form.Label>
              <Form.Control required type="text" placeholder="Model year" />
            </Form.Group>
          </Form.Row>
          <Form.Row style={{ alignItems: 'flex-end' }}>
            <Form.Group as={Col} md="5" controlId="validationCustom01">
              <Form.Label>Product type</Form.Label>
              <Form.Control required type="text" placeholder="Product type" />
            </Form.Group>
            <Form.Group as={Col} md={{ span: 5, offset: 1 }} controlId="validationCustom01">
              <button
                type="submit"
                style={{
                  width: '100%',
                  border: 'none',
                  padding: '8px 0',
                  background: '#333',
                  fontWeight: 'bold',
                  letterSpacing: '.8px',
                  borderRadius: '3px',
                }}
              >
                <Link to="/search-result" style={{ color: '#fff' }}>
                  Search
                </Link>
              </button>
            </Form.Group>
          </Form.Row>
        </Tab>
        <Tab
          eventKey="reference"
          title="Reference"
          style={{
            minHeight: '200px',
            background: '#fff',
            border: '1px solid #dee2e6',
            borderTop: 'none',
            padding: '20px',
          }}
        >
          2
        </Tab>
      </Tabs>
    </div>
  );
};

export default SearchTab;
