import React from 'react';
import '../styles/product-card.css';

// import ExpansionValve from '../images/expansion_valve.jpg';
// import BlowMotor from '../images/pro-blow-motor.jpg';

const ProductCard = ({ category }) => {
  return (
    <div className="ProductCard">
      {/* <div className="ProductCard--watermark">JNA Aircon</div> */}
      {/* <div className="ProductCard__img">
        <img src={ExpansionValve} className="ProductCard__left--img" width="100%" height="100%" alt="product" />
      </div> */}
      <div className="ProductCard__meta">
        <div className="ProductCard__meta--title">{category}</div>
      </div>
    </div>
  );
};

export default ProductCard;
