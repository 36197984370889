import React from 'react';
import '../../styles/pages/catalog.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'gatsby';

// Components
import Layout from '../layout';
import SearchTab from '../search-tab';
import Header from '../header';
import ProductCard from '../product-card';

const catalog = {
  en: {
    catalog: 'Catalog',
    quickSearch: 'Quick search',
    ac: 'Air Conditioning',
    cs: 'Cooling System',
  },
  zh: {
    catalog: '目錄',
    quickSearch: '快速搜尋',
    ac: '空調系統',
    cs: '冷卻系統',
  },
};

const Catalog = (props) => {
  const {
    pageContext: { langKey },
  } = props;
  return (
    <Layout>
      <Container>
        <div className="Catalog">
          <Header title={catalog[langKey].catalog} />
          <p>{catalog[langKey].quickSearch}</p>
          <SearchTab />
          <p>By products</p>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <Row>
                <p style={{ marginLeft: '15px' }}>{catalog[langKey].ac}</p>
                <Col md={12} style={{ padding: '5px 15px' }}>
                  <Link to="/catalog/compressor">
                    <ProductCard category="Compressor" />
                  </Link>
                </Col>
                <Col md={12} style={{ padding: '5px 15px' }}>
                  <Link to="/catalog/filter-dryer">
                    <ProductCard category="Filter Dryer" />
                  </Link>
                </Col>
                <Col md={12} style={{ padding: '5px 15px' }}>
                  <Link to="/catalog/expansion-valve">
                    <ProductCard category="Expansion Valve" />
                  </Link>
                </Col>
                <Col md={12} style={{ padding: '5px 15px' }}>
                  <Link to="/catalog/condenser">
                    <ProductCard category="Condenser" />
                  </Link>
                </Col>
                <Col md={12} style={{ padding: '5px 15px' }}>
                  <Link to="/catalog/blower-motor">
                    <ProductCard category="Blower Motor" />
                  </Link>
                </Col>
                <Col md={12} style={{ padding: '5px 15px' }}>
                  <Link to="/catalog/expansion-valve">
                    <ProductCard category="Evaporator" />
                  </Link>
                </Col>
              </Row>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <Row>
                <p style={{ marginLeft: '15px' }}>{catalog[langKey].cs}</p>
                <Col md={12} style={{ padding: '5px 15px' }}>
                  <Link to="/catalog/radiator">
                    <ProductCard category="Radiator" />
                  </Link>
                </Col>
                <Col md={12} style={{ padding: '5px 15px' }}>
                  <Link to="/catalog/cooling-fan">
                    <ProductCard category="Cooling Fan" />
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
    </Layout>
  );
};

export default Catalog;
